<template>
  <span>
    <template v-if="chargerConnectionState">
      <span style="font-size: 0.7rem">{{
        chargerConnectionState.centralSystemId
      }}</span>
      <span
        style="font-size: 0.7rem"
        v-if="
          chargerConnectionState.ocppProtocol &&
          chargerConnectionState.ocppProtocol.version === 'V_16'
        "
        class="text-grey ml-2"
        >OCPP 1.6-{{
          chargerConnectionState.ocppProtocol.transport.charAt(0)
        }}</span
      >
      <span
        style="font-size: 0.7rem"
        v-if="
          chargerConnectionState.ocppProtocol &&
          chargerConnectionState.ocppProtocol.version === 'V_20'
        "
        class="text-grey ml-2"
        >OCPP 2.0-{{
          chargerConnectionState.ocppProtocol.transport.charAt(0)
        }}</span
      >
      <time-ago
        class="timeAgo"
        v-if="chargerTimestamp"
        :refresh="90"
        :long="false"
        :tooltip="true"
        :datetime="chargerTimestamp"
      />
    </template>
    <template v-else>
      <span style="font-size: 0.7rem">{{ "N/A" }}</span>
    </template>
  </span>
</template>
<script>
import TimeAgo from "vue2-timeago";
export default {
  components: {
    TimeAgo,
  },
  props: {
    chargerId: String,
  },
  computed: {
    chargerConnectionState() {
      return this.$store.getters.getConnectionStateByChargerId(this.chargerId);
    },
    chargerTimestamp() {
      return this.$store.getters.getConnectionUpdateTimestampByChargerId(
        this.chargerId
      );
    },
  },
};
</script>
