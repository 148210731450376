var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"chargersView"}},[_c('v-card',[_c('v-card-title',[_c('v-checkbox',{attrs:{"test-id":"faulty-status-checkbox"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pt-2"},[_vm._v("Faulty status")])]},proxy:true}]),model:{value:(_vm.showOnlyFaultyStatus),callback:function ($$v) {_vm.showOnlyFaultyStatus=$$v},expression:"showOnlyFaultyStatus"}}),_c('v-checkbox',{staticClass:"pl-5",attrs:{"test-id":"chargers-linked-checkbox"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pt-2"},[_vm._v("Disconnected linked chargers")])]},proxy:true}]),model:{value:(_vm.showOnlyChargersLinked),callback:function ($$v) {_vm.showOnlyChargersLinked=$$v},expression:"showOnlyChargersLinked"}}),_c('v-text-field',{staticClass:"pl-5",attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","solo":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.isChargersLoading,"headers":_vm.headers,"items":_vm.expandedChargerList,"search":_vm.search,"expanded":_vm.expanded,"item-key":"externalId","expand-on-click":"","single-expand":"","footer-props":{
        'items-per-page-options': [20, 40, 60, -1],
      },"items-per-page":20,"dense":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"item.serialNumber",fn:function(ref){
      var item = ref.item;
return [_c('ChargerStatus',{attrs:{"chargerId":item.externalId}}),_c('router-link',{staticClass:"pa-md-4",attrs:{"to":{
            name: 'detailsView',
            params: { chargerId: item.externalId },
          }}},[_c('span',{attrs:{"id":"charger"}},[_vm._v(_vm._s(item.serialNumber))])]),_c('ChargerOverview',{attrs:{"chargerId":item.externalId}})]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('div',{staticClass:"datatable-cell-wrapper elevation-0"},[_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-iterator',{attrs:{"items":item.connectorStatuses,"item-key":"connectorId","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('small',[_vm._v("No connector data available for this charger")])]},proxy:true},{key:"default",fn:function(ref){
          var items = ref.items;
return [_c('v-simple-table',{staticClass:"elevation-0",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((items),function(connector){return _c('tr',{key:connector.connectorId},[_c('td',[_c('ConnectorStatus',{attrs:{"chargerId":item.externalId,"connectorId":connector.connectorId,"meterValue":_vm.getMeterValuesForConnector(
                                connector.connectorId
                              )}}),_c('span',{staticClass:"pa-md-2"}),_vm._v(" "+_vm._s(_vm.assignConnectorName(connector.connectorId))+" ")],1),_c('td',[_vm._v(_vm._s(connector.errorCode))]),_c('td',{staticClass:"pa-md-4"},[_c('SelectedMeterValue',{attrs:{"chargerId":item.externalId,"connectorId":connector.connectorId,"rawMeterValues":_vm.meterValues}})],1)])}),0)]},proxy:true}],null,true)})]}}],null,true)})],1)])]}}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"green","indeterminate":""}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }