<template>
  <span>
    <template
      v-if="selectedConnectorStatuses && selectedConnectorStatuses.connected"
    >
      <span
        v-for="(statuses, index) in selectedConnectorStatuses.connectorStatuses"
        :key="index"
      >
        <template v-if="statuses.connectorId === connectorId">
          <status-indicator
            v-if="statuses.status === 'Available'"
            v-b-tooltip.hover
            title="Available"
            style="cursor: default"
            status="positive"
          />
          <status-indicator
            v-if="statuses.status === 'Preparing'"
            v-b-tooltip.hover
            title="Preparing"
            style="cursor: default"
            status="positive"
          />
          <template
            v-if="
              meterValue !== undefined &&
              meterValue !== null &&
              meterValue.CurrentImportInlet !== undefined &&
              meterValue.PowerActiveImport !== undefined
            "
          >
            <status-indicator
              v-if="
                statuses.status === 'Charging' &&
                (meterValue.CurrentImportInlet.value > null ||
                  meterValue.PowerActiveImport.value > 0)
              "
              v-b-tooltip.hover
              title="Charging"
              style="cursor: default"
              status="active"
            />
            <status-indicator
              v-else-if="
                statuses.status === 'Charging' &&
                (meterValue.CurrentImportInlet.value < 0 ||
                  meterValue.PowerActiveImport.value < 0)
              "
              v-b-tooltip.hover
              title="Discharging"
              style="cursor: default"
              status="active"
            />
          </template>
          <template v-else>
            <status-indicator
              v-if="statuses.status === 'Charging'"
              v-b-tooltip.hover
              title="Charging"
              style="cursor: default"
              status="active"
            />
          </template>
          <status-indicator
            v-if="statuses.status === 'SuspendedEVSE'"
            v-b-tooltip.hover
            title="SuspendedEVSE"
            style="cursor: default"
            status="positive"
          />
          <status-indicator
            v-if="statuses.status === 'SuspendedEV'"
            v-b-tooltip.hover
            title="SuspendedEV"
            style="cursor: default"
            status="positive"
          />
          <status-indicator
            v-if="statuses.status === 'Finishing'"
            v-b-tooltip.hover
            title="Finishing"
            style="cursor: default"
            status="positive"
          />
          <status-indicator
            v-if="statuses.status === 'Reserved'"
            v-b-tooltip.hover
            title="Reserved"
            style="cursor: default"
            status="positive"
          />
          <status-indicator
            v-if="statuses.status === 'Unavailable'"
            v-b-tooltip.hover
            title="Unavailable"
            style="cursor: default"
            status="positive-transparent"
          />
          <status-indicator
            v-if="statuses.status === 'Faulted'"
            v-b-tooltip.hover
            title="Faulted"
            style="cursor: default"
            status="negative"
          />
          <status-indicator
            v-if="statuses.status === 'Occupied'"
            v-b-tooltip.hover
            title="Occupied"
            style="cursor: default"
            status="positive"
          />
        </template>
      </span>
    </template>
    <status-indicator
      v-else
      v-b-tooltip.hover
      title="Disconnected"
      style="cursor: default"
      status=""
    />
  </span>
</template>

<script>
import { StatusIndicator } from "vue-status-indicator";

export default {
  name: "ChargerStatus",
  props: ["chargerId", "connectorId", "meterValue"],
  components: {
    StatusIndicator,
  },
  computed: {
    selectedConnectorStatuses() {
      return this.$store.getters.getChargerStatusByChargerId(this.chargerId);
    },
  },
};
</script>
