import { OCPP_API } from "@/api/common/http-common";
import moment from "moment";

export function createCustomerCharger(customerCharger) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.post(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customerchargers`,
    customerCharger
  );
}

export function getCustomerChargers() {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.get(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customerchargers`
  );
}

export function getCustomerChargerOwners(start) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.get(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customerchargers/chargerowners?start=${moment(
      start
    ).toISOString()}`
  );
}

export function updateCustomerCharger(customerCharger) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.put(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customerchargers/${customerCharger.id}`,
    customerCharger
  );
}

export function deleteCustomerCharger(id, amendOwnerlessChargers) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.delete(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customerchargers/${id}?amendOwnerlessChargers=${amendOwnerlessChargers}`
  );
}

export function resendActivationMail(id) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.post(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customerchargers/${id}/activationlink`
  );
}
