<template>
  <div class="mr-5 ml-5 mt-4 fontStyle">
    <div>
      <EndpointConnectionBadge />
    </div>
    <div>
      <div
        class="d-flex align-items-center p-3 my-3 text-white-50 rounded bg-charger-box"
      >
        <div class="lh-100">
          <h6 class="mb-0 text-white lh-100">Chargers group</h6>
        </div>
      </div>
      <div class="rounded shadow-sm">
        <ChargerListTable />
      </div>
    </div>
  </div>
</template>

<script>
import ChargerListTable from "@/components/ChargerListTable";
import EndpointConnectionBadge from "@/components/EndpointConnectionBadge";

export default {
  name: "chargersView",
  components: {
    EndpointConnectionBadge,
    ChargerListTable,
  },
  beforeMount() {
    this.$store.dispatch("initEssentialDatasets");
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
