<template>
  <v-app id="chargersView">
    <v-card>
      <v-card-title>
        <v-checkbox
          v-model="showOnlyFaultyStatus"
          test-id="faulty-status-checkbox"
        >
          <template v-slot:label>
            <div class="pt-2">Faulty status</div>
          </template>
        </v-checkbox>
        <v-checkbox
          v-model="showOnlyChargersLinked"
          class="pl-5"
          test-id="chargers-linked-checkbox"
        >
          <template v-slot:label>
            <div class="pt-2">Disconnected linked chargers</div>
          </template>
        </v-checkbox>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
          solo
          dense
          class="pl-5"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="isChargersLoading"
        :headers="headers"
        :items="expandedChargerList"
        :search="search"
        :expanded.sync="expanded"
        item-key="externalId"
        expand-on-click
        single-expand
        :footer-props="{
          'items-per-page-options': [20, 40, 60, -1],
        }"
        :items-per-page="20"
        dense
        class="elevation-0"
        @click:row="expandRow"
        ><template slot="progress">
          <v-progress-linear color="green" indeterminate></v-progress-linear>
        </template>
        <template #item.serialNumber="{ item }">
          <ChargerStatus :chargerId="item.externalId" />
          <router-link
            class="pa-md-4"
            :to="{
              name: 'detailsView',
              params: { chargerId: item.externalId },
            }"
          >
            <span id="charger">{{ item.serialNumber }}</span>
          </router-link>
          <ChargerOverview :chargerId="item.externalId" />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <div class="datatable-cell-wrapper elevation-0">
            <td :colspan="headers.length">
              <v-data-iterator
                :items="item.connectorStatuses"
                item-key="connectorId"
                hide-default-footer
              >
                <template v-slot:no-data>
                  <small>No connector data available for this charger</small>
                </template>
                <template v-slot:default="{ items }">
                  <v-simple-table dense class="elevation-0">
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="connector in items"
                          :key="connector.connectorId"
                        >
                          <td>
                            <ConnectorStatus
                              :chargerId="item.externalId"
                              :connectorId="connector.connectorId"
                              :meterValue="
                                getMeterValuesForConnector(
                                  connector.connectorId
                                )
                              "
                            /><span class="pa-md-2"></span>
                            {{ assignConnectorName(connector.connectorId) }}
                          </td>
                          <td>{{ connector.errorCode }}</td>
                          <td class="pa-md-4">
                            <SelectedMeterValue
                              :chargerId="item.externalId"
                              :connectorId="connector.connectorId"
                              :rawMeterValues="meterValues"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-data-iterator>
            </td>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import { getChargers } from "@/api/ChargersListApi";
import { subMinutes, differenceInMilliseconds } from "date-fns";
import SelectedMeterValue from "./SelectedMeterValue";
import ChargerStatus from "../components/ChargerStatus";
import { mapState } from "vuex";
import ConnectorStatus from "@/components/ConnectorStatus";
import ChargerOverview from "@/components/ChargerOverview";
import { getMeterValueForCharger } from "@/api/GetMeterValueApi";
import { getCustomerChargerOwners } from "@/api/CustomerManagementApi";
import moment from "moment";

export default {
  headerStyling: "black--text font-weight-bold",
  components: {
    ChargerStatus,
    SelectedMeterValue,
    ConnectorStatus,
    ChargerOverview,
  },
  data() {
    return {
      showOnlyFaultyStatus: false,
      showOnlyChargersLinked: false,
      search: "",
      expanded: [],
      meterValues: [],
      chargersList: [],
      chargerCustomerOwners: [],
      singleExpand: false,
      isChargersLoading: true,
      panel: [],
      headers: [
        {
          text: "Manufacturer ID",
          align: "start",
          sortable: true,
          value: "serialNumber",
          class: this.$options.headerStyling,
        },
        {
          text: "Vendor",
          value: "brand",
          class: this.$options.headerStyling,
        },
        {
          text: "Model",
          value: "model",
          class: this.$options.headerStyling,
        },
        {
          text: "Notes",
          value: "notes",
          class: this.$options.headerStyling,
        },
        { text: "", value: "data-table-expand" },
        {
          text: "",
          value: "isLinked",
          filter: this.isLinkedFilter,
          align: " d-none",
        },
        {
          text: "",
          value: "connectorStatuses",
          filter: this.isFaultedFilter,
          align: " d-none",
        },
      ],
    };
  },
  methods: {
    getChargersList() {
      const vm = this;
      getChargers()
        .then((response) => {
          vm.chargersList = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.isChargersLoading = false;
        });
    },
    getChargerCustomerList() {
      getCustomerChargerOwners(Date.now())
        .then((response) => {
          this.chargerCustomerOwners = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return [];
    },
    isLinkedFilter(value) {
      if (!this.showOnlyChargersLinked) {
        return true;
      }
      return value;
    },
    isFaultedFilter(value) {
      if (!this.showOnlyFaultyStatus) {
        return true;
      }
      return (
        value !== undefined &&
        value
          .map((connectorStatus) => connectorStatus.status)
          .find((status) => status === "Faulted") !== undefined
      );
    },
    clearMeterValues() {
      this.meterValues = [];
    },
    getMeterValuesForConnector(connectorId) {
      return this.meterValues.find((c) => c.connectorId === connectorId)
        ?.meterValues;
    },
    expandRow(item, slot) {
      try {
        const vm = this;
        this.clearMeterValues();
        if (!slot.isExpanded && item.connectorStatuses) {
          getMeterValueForCharger(item.externalId).then((res) => {
            vm.meterValues = res.data;
          });
        }
      } finally {
        slot.expand(!slot.isExpanded);
      }
    },
    assignConnectorName: function (name) {
      if (name === "0") {
        name = "Main Controller";
      } else {
        name = `Connector: ${name}`;
      }
      return name;
    },
    isChargerLinkedAndDisconnected(chargerExternalId) {
      const latestConnectionTimestamp = this.$store.getters.getConnectionUpdateTimestampByChargerId(
        chargerExternalId
      );
      const isDisconnected =
        latestConnectionTimestamp !== undefined
          ? Math.abs(
              moment(latestConnectionTimestamp).diff(Date.now(), "seconds")
            ) > 300
          : true;
      return (
        this.chargerCustomerOwners.find(
          (cco) => cco.externalId === chargerExternalId
        ) !== undefined && isDisconnected
      );
    },
  },
  computed: {
    ...mapState({
      expandedChargerList(state, getters) {
        const chargers = [...this.chargersList];
        chargers.forEach((charger) => {
          const connector = getters.getChargerStatusByChargerId(
            charger.externalId
          );
          charger.errorCode = connector ? connector.errorCode : "N/A";
          charger.connectorStatuses = connector
            ? connector.connectorStatuses
            : connector;
          charger.isLinked = this.isChargerLinkedAndDisconnected(
            charger.externalId
          );
        });
        return chargers;
      },
    }),
  },
  mounted() {
    this.getChargersList();
    this.getChargerCustomerList();
    const tokenExpiryDate = localStorage.getItem("tokenExpiry");
    const twoMinutesBeforeExpiry = subMinutes(Date.parse(tokenExpiryDate), 2);
    const diff = differenceInMilliseconds(twoMinutesBeforeExpiry, Date.now());
    if (diff > 0) {
      setInterval(() => location.reload(), diff);
    }
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>

<style>
.timeAgo span {
  padding-left: 12px;
  font-size: 0.7rem;
  color: lightslategray;
  font-style: normal;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}
.datatable-cell-wrapper {
  width: inherit;
  position: relative;
  z-index: 4;
  padding: 10px 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.b-table-details td {
  padding-left: 25px;
  background-color: #f9f5f5;
}
.largeColumn {
  width: 40%;
}
.smallColumn {
  width: 20%;
}
tr:focus {
  outline: none;
}
</style>
<style scoped>
.table > tbody > tr.b-table-row-selected,
.table > tbody > tr.b-table-row-selected > td,
.table > tbody > tr.b-table-row-selected > th {
  background-color: #f5f5f5;
}
</style>
