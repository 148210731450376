<template>
  <div style="text-align: right">
    <template v-if="!isConnectionStateDatasetInitialized">
      <div style="display: inline-block">
        <b-skeleton-img
          variant="light"
          class="ml-1"
          width="200px"
          height="200px"
        ></b-skeleton-img>
      </div>
    </template>
    <template v-else>
      <template v-if="availableEndPoints && availableEndPoints.length !== 0">
        <template v-for="endPoint in availableEndPoints">
          <b-badge
            variant="light"
            :key="endPoint.centralSystemId"
            class="ml-1"
            style="width: 200px; height: 200px; display: inline-block"
          >
            <span style="font-size: 160px" class="pb-5 pt-4">
              {{ endPoint.count }}
            </span>
            <div class="border-top">
              <h4>{{ endPoint.centralSystemId }}</h4>
            </div>
          </b-badge>
        </template>
      </template>
      <b-badge
        v-else-if="!availableEndPoints"
        variant="light"
        style="width: 200px; height: 200px"
      >
        <svg style="width: 80px; height: 80px; margin-top: 30%">
          <use xlink:href="@/assets/svg/icons.svg#file-broken" />
        </svg>
      </b-badge>
    </template>
  </div>
</template>

<script>
import { BBadge, BSkeletonImg } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  name: "OcppEndpointInstance",
  components: {
    "b-badge": BBadge,
    "b-skeleton-img": BSkeletonImg,
  },
  computed: mapState({
    isConnectionStateDatasetInitialized: "isConnectionStateDatasetInitialized",
    availableEndPoints(state) {
      const chargerConnectionState = state.connectionStates;
      const endPoint = {};
      for (const index in chargerConnectionState) {
        const key = chargerConnectionState[index].centralSystemId;
        if (chargerConnectionState[index].connected) {
          endPoint[key] = {
            centralSystemId: key,
            count:
              endPoint[key] && endPoint[key].count
                ? endPoint[key].count + 1
                : 1,
          };
        }
      }
      return Object.values(endPoint).sort((endpoint1, endpoint2) => {
        let comparison = 0;
        if (endpoint1.centralSystemId > endpoint2.centralSystemId) {
          comparison = 1;
        } else if (endpoint2.centralSystemId > endpoint1.centralSystemId) {
          comparison = -1;
        }
        return comparison;
      }
      );
    },
  }),
};
</script>
